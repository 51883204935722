import React, { useState } from "react";
import { graphql } from "gatsby";
import GatsbyImg from "gatsby-image";
import styled from "styled-components";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { PageTitle } from "../components/pageTitle";
import Hero from "../components/hero";
import RichText from "../components/richText";
import Gallery from "react-photo-gallery";
import FsLightbox from "fslightbox-react";
import { focusState } from "../tokens/a11y";

const galleryItemMargin = 12;

const GatsbyImage = ({ photo, index, onClick }) => {
  return (
    <ImageButton
      photo={photo}
      key={photo.key}
      onClick={(e) => {
        return onClick(e, { index, photo });
      }}
    >
      <GatsbyImg fluid={photo.fluid} />
    </ImageButton>
  );
};

const ImageButton = styled.button`
  width: ${({ photo }) => photo.width}px;
  height: ${({ photo }) => photo.height}px;
  margin: ${galleryItemMargin}px;
  padding: 0;
  border: none;
  cursor: zoom-in;

  &:hover,
  &:focus {
    ${focusState};
  }
`;

const getImages = (imageArray) => {
  return [...imageArray].map(({ fluid, id, file }) => ({
    height: file.details.image.height,
    width: file.details.image.width,
    src: fluid.src,
    srcSet: fluid.srcSet,
    fluid,
    key: id,
  }));
};

function GalleryPageTemplate({ data }) {
  const { title, body } = data.contentfulPage;
  const breadcrumbs = [{ name: "Home", href: "/" }, { name: title }];

  const { thumbnails, images } = data.contentfulImageGallery;

  const formattedImages = getImages(thumbnails);

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const imageClick = (e, obj) => {
    setCurrentImage(obj.index + 1);
    setViewerIsOpen(!viewerIsOpen);
  };

  const lightboxImages = images.map((image) => image.fluid.src);

  return (
    <Layout
      hero={
        <Hero breadcrumbs={breadcrumbs}>
          <PageTitle>{title}</PageTitle>
        </Hero>
      }
    >
      <SEO title={title} />
      <MaxWidthBox>
        <RichText>{renderRichText(body)}</RichText>
      </MaxWidthBox>
      <div
        css={`
          margin-top: 2rem;
          margin-right: -${galleryItemMargin}px;
          margin-left: -${galleryItemMargin}px;
        `}
      >
        <Gallery
          photos={formattedImages}
          renderImage={GatsbyImage}
          targetRowHeight={250}
          margin={galleryItemMargin}
          onClick={imageClick}
        />
        {/* {viewerIsOpen && (
          <DialogOverlay isOpen={viewerIsOpen} onDismiss={closeViewer}>
            <DialogContent>
              <GatsbyImg fluid={images[currentImage].fluid} />
              <button
                type="button"
                onClick={closeViewer}
                css="position: absolute; top: 0; right: 0;"
              >
                Close
              </button>
            </DialogContent>
          </DialogOverlay>
        )} */}
        <FsLightbox
          toggler={viewerIsOpen}
          sources={lightboxImages}
          slide={currentImage}
        />
      </div>
    </Layout>
  );
}

export default GalleryPageTemplate;

export const pageQuery = graphql`
  query GalleryPageBySlug($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      title
      slug
      body {
        raw
      }
    }
    contentfulImageGallery(contentful_id: { eq: "MSn6taoKdupiNW0U4aDWH" }) {
      id
      name
      thumbnails: images {
        id
        title
        fluid(maxWidth: 400) {
          ...GatsbyContentfulFluid_withWebp
        }
        file {
          details {
            image {
              width
              height
            }
          }
        }
      }
      images {
        id
        title
        fluid(maxWidth: 1600) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`;

const MaxWidthBox = styled.div`
  max-width: 60ch;
`;
